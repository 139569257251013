.errorScreen.sceneLoadingScreen {
  .contentContainer {
    max-width: 85%;

    h2 {
      text-align: center;
      color: black;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    p {
      text-align: center;
      color: darkgrey;
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    button {
      color: white;
      background-color: #ff6600;
      border: none;
      box-shadow: 1px 1px 7px 1px lightgrey;
      width: 30%;
      min-width: 100px;
      padding: 10px;
      border-radius: 8px;

      &:hover {
        background-color: #ff6600;
      }
    }
  }
}
