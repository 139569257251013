.Stepper {
  // removes number in center of circle
  .MuiStepIcon-text {
    display: none;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 7px;
  }

  &.MuiPaper-root {
    background-color: inherit;
  }
}
