$md: 769px !default;

.Widgets {
  position: relative;
  z-index: 2001;
  pointer-events: all;

  .float-left {
    position: absolute;
    left: 0;
  }

  .float-right {
    position: absolute;
    right: 0;
  }

  .toggleSizeButton {
    img {
      width: 24px;
    }
  }

  .shareButton {
    img {
      width: 15px;
    }
  }

  .changeEnvButton {
    img {
      width: 34px;
    }
  }

  // customer support
  .supportCard {
    width: 335px;
    max-height: 75vh;
    position: fixed;
    top: 65px;
    left: 25px;
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    z-index: 2;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: $md) {
      left: 370px;
      max-height: 100vh;
    }

    button {
      background-color: rgba(5, 79, 174, 0.7);
    }

    h4 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
    }

    .topSection {
      height: 120px;
      color: white;
      border-radius: 8px 8px 0px 0px;
      background: rgb(93, 188, 210);
      background: linear-gradient(45deg, rgba(93, 188, 210, 1) 0%, rgba(5, 79, 174, 0.7) 100%);
    }

    .bottomSection {
      background: white;
      padding: 15px 0px;

      .form-group {
        button {
          display: -webkit-box;
        }

        .asterisk {
          color: #ff0000;
        }

        .form-control,
        .custom-select {
          background-color: white;
          border-radius: 8px;
          box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        }
      }

      .submitNote {
        text-align: center;
        padding-bottom: 30px;

        img {
          width: 70%;
        }
      }
    }
  }
}
