$md: 768px !default;
$sm: 576px;
$lightTheme: #f5f6f4 !default;
$grey: #4f4f4f;
$grey55: #8c8c8c;
$clientColor: #ff6600;
$white: #ffffff;
$green: #028513;
$brightGrey: #e9edf0;

.CardElementMaterials {
  .itemsCard {
    background-color: #eee;
  }
}

/*
  Styles for Item Cards
  Shopping Cart Card Styles are below
*/
.cardBadge {
  width: 33px;
  transform: translate(-10px, -10px);
  position: absolute;
}

.itemsCard {
  background-color: $lightTheme;
  cursor: pointer;
  box-shadow: 0 5px 10px 2px $grey55;
  border: none !important;
  min-height: 165px;
  margin-top: 2%;
  border-radius: 8px;

  &:hover {
    @media (min-width: $md) and (orientation: landscape) {
      box-shadow: 1px 1px 2px 0px #999999;
    }
  }

  &.active {
    border: 2px dashed $clientColor !important;
  }

  &.soldOut {
    background-color: #d3d3d3; /* Light grey background */
    cursor: default; /* Change cursor to default */
    opacity: 0.6; /* Reduce opacity to make it look disabled */
    box-shadow: none; /* Remove shadow to further indicate it's inactive */
  }

  .price {
    color: $green;
    border-radius: 3px 3px 3px 3px;
    background-color: $brightGrey;
  }

  picture {
    justify-content: center;
    display: flex;
  }

  .thumbnail {
    width: 85%;
  }

  .checkMark {
    position: absolute;
    background-color: $white;
    border-radius: 50%;
    left: -12px;
    top: -10px;
    width: 25px;
    // mobile
    @media screen and (orientation: landscape) {
      left: -12px;
      top: -10px;
      width: 25px;
    }
  }
  #modalButton {
    background-color: $clientColor;
    box-shadow: 0 1px 3px 2px $grey55;
    color: $white;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
  }

  &.disabled {
    opacity: 0.5;
  }

  .body {
    flex: auto;
  }

  .title {
    text-align: center;
    @media screen and (orientation: landscape) {
      font-size: initial;
    }
    @media screen and (orientation: portrait) {
      font-size: 14px;
    }
  }

  .description {
    text-align: center;
    font-size: 11px;
    font-style: italic;
    color: $grey;
  }

  .size {
    font-size: 12px;
    color: $grey;
  }
  .spec {
    font-weight: 500;

    font-size: 14px;
    @media (min-width: $sm) {
      font-size: initial;
    }
  }
}

/*
  Styles for Shopping Cart Cards
*/
.cartCard {
  cursor: default;
  box-shadow: 0 1px 10px 3px $grey55;
  border: none !important;
  margin-top: 2%;
  min-height: 197px;

  &.deskModel,
  &.accessories {
    .productImage {
      width: 70%;
      object-fit: contain;
    }
  }

  &.baseMaterial,
  &.deskMaterial {
    .productImage {
      width: auto;
      max-height: 100px;
    }
  }

  .price {
    color: $green;
  }

  .topBorderColor {
    height: 15px;
    background: $clientColor;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .card-title {
    font-size: 14px;
    color: #777;
  }
  .card-text {
    font-weight: 400;
    font-size: 15px;
  }

  picture {
    justify-content: center;
    display: flex;
  }
}

// used for accessories with more info modals
.ProductDetailsModal {
  .modal-header {
    background-color: $clientColor;
    color: $white;
  }
  picture {
    justify-content: center;
    display: flex;
  }
  img {
    width: 250px;
  }
  p {
    @media screen and (orientation: portrait) {
      font-size: 14px;
    }
    @media screen and (orientation: landscape) {
      font-size: initial;
    }
  }
  a {
    color: $white;
  }
}

// moves overlay in front of widgets TODO: clean up zIndex around the app
.modal-backdrop {
  z-index: 2002 !important;
}

.itemsCard .card-title {
  text-align: center;
  background: #747474;
  margin-top: 10px;
  margin-bottom: 0px !important;
  padding: 5px 0px;
  border-radius: 0px 0px 6px 6px;
  color: #ffffff;
}

.description {
  white-space: break-spaces;
}
.no-top-radius {
  border-radius: 0px !important;
}
