.DeskHeightController {
  width: 145px;
  height: 60px;
  background-color: black;
  border-radius: 6px;
  border-style: solid;
  border-color: #bcbcbc;
  border-width: 3px;

  h3,
  p {
    color: #dddcff;
  }

  h3 {
    font-family: "DS-Digital", sans-serif;
  }

  p {
    font-size: 11px;
  }

  img {
    cursor: pointer;
    width: 20%;
  }

  .rotate {
    transform: rotate(180deg);
  }
}
