.ShoppingCart {
  h4 {
    color: #222;
  }

  h5 {
    font-weight: bold;
    color: #222;
  }

  .backButton {
    width: 45px;
    cursor: pointer;
  }

  button {
    background-color: #ff6600;
    color: white;
    box-shadow: 0px 1px 2px #888888;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: #ff6600;
    }
  }

  .checkoutBtn:disabled {
    background-color: #b5b4b4;
    &:hover {
      background-color: #b5b4b4;
    }
  }
}
