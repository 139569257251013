.DropDown {
  .wrapper {
    border: none;
    max-width: 180px;
    border-radius: 7px;
    padding: 0 13px;
    outline: none;
    height: 60px;
  }

  .dropDownContent {
    background: #ffffff;
    border-radius: 6px;
  }

  .dropDownItem:hover {
    background: #f5f6f4;
    border-radius: 6px;
  }
}
