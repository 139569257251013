.TabsContainer {
  background-color: #e9edf0;
  border-radius: 20px;
  width: fit-content;
  height: 55px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);

  .tab {
    cursor: pointer;
    width: 150px;
    font-size: 14px;
  }

  .active {
    background-color: #ff6600;
    border-radius: 12px;

    color: white;
  }
}
