.navigation {
  img {
    cursor: pointer;
    width: 62px;

    &.disable {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }

  &.sticky {
    position: sticky;
    background-color: inherit;
    top: -1px;
    z-index: 3;
    margin-right: -12px; // break out of container padding
    margin-left: -12px;

    &.isSticking {
      box-shadow: 0px 3px 8px 1px #555;
    }
  }
}
