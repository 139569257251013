$primary: #ff6600;
$body-color: #424345;

:root {
  --muted: #c9c9c9;
  --border-muted: #eee;
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap");
// used for desk height controller
@font-face {
  font-family: "DS-Digital";
  src: local("DS-Digital"), url("/fonts/DS-DIGIT.TTF") format("truetype");
  font-weight: bold;
}

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
  cursor: auto !important;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
.btn {
  font-family: "Roboto", sans-serif;
  user-select: text;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

button:focus {
  outline: 0;
}

.displayNone {
  display: none;
}

.MuiLinearProgress-root {
  width: 100% !important;
  background: linear-gradient(90deg, $primary 0%, $body-color 53%, $primary 100%);
}

.MuiLinearProgress-barColorPrimary {
  background-color: #000000 !important;
}

// #screenshot_canvas {
//   position: absolute;
//   z-index: 100000000000000000000;
//   width: 50vw;
//   bottom: 25px;
//   left: 0;
//   border: 5px solid black;
// }
