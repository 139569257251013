.accordion_item {
  width: 100%;
  padding: 17px 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #f5f6f4;
  box-shadow: 0 1px 10px 3px #8c8c8c;
  border-radius: 8px;

  .accordion_header:hover {
    h5 {
      color: #ff6600 !important;
    }
  }
}
