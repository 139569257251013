#BuildSection {
  position: fixed;
  z-index: 1000;
  overflow-y: scroll;
  background-color: #f7f7f7;
  left: 0;

  @media screen and (orientation: landscape) {
    top: 0;
    height: 100%;
    // this needs to match width and margin-left of .shared-scene-sizing in BuilderScreen styles
    width: 450px;
  }

  @media screen and (orientation: portrait) {
    bottom: 0;
    height: 55%;
    width: 100%;
  }

  .headers {
    color: black;
  }
}
